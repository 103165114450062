.footer {
    display: flex;
    margin-top: 5px;
    padding: 50px;
    background-color: #262626;
    text-align: center;
}

.footerText {
    font: 20px/28px Gotham-Book;
    color: #FFFFFF;
    margin-top: 20px;
}

.logo {
    height: 70px;
}