body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Cubano-Regular';
  src: local('Cubano-Regular'), url(./fonts/Cubano-Regular.otf) format('opentype');
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family: 'Cubano';
  src: local('Cubano'), url(./fonts/Cubano-Regular.otf) format('opentype');
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url(./fonts/Gotham-Bold.otf) format('opentype');
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book'), url(./fonts/Gotham-Book.otf) format('opentype');
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium'), url(./fonts/Gotham-Medium.otf) format('opentype');
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

.noOverflow {
  overflow-x: hidden;
}

.wbrButton {
  font: 23px/33px Gotham-Medium;
  padding: 15px;
  width:100%;
  border: 2px solid #EED247;
  border-radius: 20px;
  text-align: center;
  color: #094D92;
}

.wbrButton:hover {
  background-color: #EED247;
  cursor: pointer;
}

.bannerContent {
  margin: auto;
  color: #FFFFFF;
  text-align: center;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 400px;
}

.bannerButton {
  margin:auto;
  width: 254px;
  color: #FFFFFF;
}

.bannerButton:hover {
  color: #094D92;
}

.bannerTitle {
  font: 50px/60px Cubano;
  margin-bottom: 20px;
  text-shadow: 0 3px 6px #00000031;
}

.bannerText {
  font: 20px/25px Gotham-Book;
  margin-bottom: 20px;
}

a {
  text-decoration: none !important;
}