.van-navbar {
    display: flex;
    background-color: #094D92;
    height: 70px;
    position: sticky;
    top: 0;
}

.van-navbar-container {
    margin: auto;
}

.van-navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.van-navbar li {
    display: inline;
    padding-left: 30px;
    font: 20px/28px Gotham-Bold;
    color: #FFFFFF;
}

.van-navbar li:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}

@media only screen and (max-width: 940px) {
    .van-navbar {
        top: 100px;
        z-index: 0;
        width: 100vw;
        position: fixed;
    }
    
    .van-navbar ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .van-navbar li{
        font: 12px/20px Gotham-Bold;
        padding: 0 10px;
    }
    
    .navbar-outer{
        padding-bottom: 100px;
    }
}