.navbar {
    display: flex;
    background-color: #262626;
    margin-bottom: 5px;
    height: 100px;
}

.navbar-container {
    margin: auto;
    width: 80%;
}

.navbar-main {
    float: right;
    list-style-type: none;
    margin: revert;
}

.navbar-item {
    display: inline;
    position: relative;
    padding-left: 30px;
    font: 24px/34px Gotham-Bold;
    color: #FFFFFF;
}

.navbar li:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}

.dropdown {
    padding-bottom: 35px;
}

/* Dropdown styles */
.dropdown-menu {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #262626;
    display: none;
    list-style-type: none;
    width: 235px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-top: 30px;
}

.dropdown-item {
    padding: 10px;
    color: white;
    cursor: pointer;
    font: normal normal normal 24px/34px Gotham-Bold;
}

.dropdown-item:hover {
    background-color: #262626;
    color: white;
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
    display: block;
}

/* Mobile stuff */
.hamburger {
    float: right;
    background: none;
    border: none;
    color: #EED247;
    font-size: 45px;
}

.mobile-menu {
    position: absolute;
    top: 100px;
    right: 0px;
    background-color: #262626;
    height: 100vh;
    padding: 0 40px;
}

.mobile-navbar-item {
    display: block;
    font: 24px / 34px Gotham-Bold;
    color: #FFFFFF;
    padding-bottom: 15px;
}

@media only screen and (max-width: 940px) {
    .navbar {
        position: fixed;
        width: 100vw;
        z-index: 9999;
    }
    
    .navbar-container {
        width: 90%;
    }
    
    .mobile-dropdown-menu {
        padding-top: 20px;
        margin: 0;
    }

    .navbar li:hover {
        text-decoration: none;
    }
}