.mainBanner {
    background-image: url('../images/homepage.png');
}

.journeyBanner {
    background-image: url('../images/journey.png');
}

.subHeading {
    font: normal normal normal 20px/28px Gotham-Bold;
    color: #094D92;
}

.campervanSection {
    padding: 90px 0;
    background-color: #fbf7f3;
}

.productDisplay {
    text-align: center;
    color: #094D92;
}

.productText {
    padding-top: 30px;
    padding-bottom: 30px;
}

.mainText {
    font: 50px/70px Gotham-Bold;
    text-align: center;
    color: #094D92;
    padding-bottom: 57px;
}

.mainDetails {
    font: 30px/42px Gotham-Bold;
    padding-bottom: 18px;
}

.subDetails {
    font: 20px/28px Gotham-Book;
    color: #094D92;
}

.getInTouch {
    padding-top: 103px;
    padding-bottom: 103px;
    width: 70%;
    margin: auto;
}

.getInTouchText {
    font: 50px/70px Gotham-Bold;
    color: #094D92;
}

.addressLine {
    padding-top: 66px;
}

.hoursSection div {
    padding-bottom: 15px;
}

.spacer {
    margin: 25px 0;
    border: 1px solid #094D92;
}

.boldText {
    font: normal normal normal 20px/28px Gotham-Bold;
}

.phoneNumber {
    padding: 30px 0;
}

.enquirySection {
    background-color: #094D92;
    color: #FFFFFF;
    font: 20px/28px Gotham-Book;
    padding: 70px 0;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    max-height: 810px;
}



.enquiryBreak {
    padding-bottom: 40px;
}

.enquirySection input {
    background: #FFFFFF;
    border-radius: 5px;
    border-style: none;
    padding: 15px;
    width: 100%;
}

.enquirySection textarea {
    background: #FFFFFF;
    border-radius: 5px;
    border-style: none;
    padding: 15px;
    width: 100%;
    height:200px;
}

textarea:focus, input:focus{
    outline: none;
}

.closeButton {
    float: right;
    cursor: pointer;
}

.enquireButton {
    margin:auto;
    color: #FFFFFF;
    background: none;
}

.enquireButton:hover {
    color: #094D92;
}

@media only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .mainBanner {
        background-image: url('../images/homepage@2x.png');
    }
}

@media only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .journeyBanner {
        background-image: url('../images/journey@2x.png');
    }
}

@media only screen and (max-width: 940px) {
    .productDisplay {
        padding-bottom: 40px;
    }
    
    .campervanSection {
        padding: 50px 0;
    }
    
    .aboutSection iframe {
        min-height: 400px;
    }
    
    .enquirySection {
        padding-top: 20px;
    }
}

.slideOut {
    max-height: 0;
    padding: 0;
    margin: 0;
}