.vanMain {
    height: 450px;
}

.vanSection {
    padding: 70px 0;
}

.introSection {
    color: #094D92;
}

.vanTitle {
    color: #F4D208;
    font: 50px/60px Cubano;
    padding-bottom: 37px;
}

.vanModel {
    font: 20px/28px Gotham-Bold;
    padding-bottom: 37px;
}

.vanSubText {
    font: 20px/28px Gotham-Book;
}

.vanAltSubText {
    font: 20px/28px Gotham-Book;
    color: white;
    padding-bottom: 35px;
}

.vanBlackSubText {
    font: 20px/28px Gotham-Book;
    color: black;
    padding-bottom: 35px;
}

.vanSectionHeading {
    font: 25px/35px Gotham-Bold;
    color: #094D92;
    padding-bottom: 50px;
}

.vanSectionAltHeading {
    font: 25px/35px Gotham-Bold;
    color: white;
    padding-bottom: 30px;
}

.vanSectionBlackHeading {
    font: 25px/35px Gotham-Bold;
    color: black;
    padding-bottom: 30px;
}

.layoutSection {
    background-color: #FBF7F3;
    color: #094D92;
}

.layoutImages {
    padding-top: 70px;
}

.specsSection {
    background-color: #FBF7F3;
    color: #094D92;
}

.specsSection li {
    font: 20px/23px Gotham-Book;
    padding-bottom: 20px;
}

.extrasSection li {
    font: 20px/40px Gotham-Book;

}

.extrasSection {
    background-color: #094D92;
    color: white;
}

.videoSection {
    width:80%;
    margin: auto;
}


@media only screen and (max-width: 940px) {
    .layoutImages img {
        padding-top: 25px;
    }
    
    .vanWrapper {
        margin-top: 70px;
    }
    
    .vanMain {
        height: 30vh;
    }
    
    .vanSection {
        text-align: center;
    }

    .vanSection li {
        text-align: left;
    }
}