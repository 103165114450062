.ourStoryText {
    margin: auto;
    font: 50px/60px Cubano;
    color: black;
    padding-bottom: 20px;
}

.ourStorySubText {
    font: 20px/25px Gotham-Book;
    padding-bottom: 20px;
    padding-right: 100px;
}

.aboutPageSection {
    padding: 80px 0;
}

.aboutBanner {
    background-image: url('../images/aboutBanner.png');
}

@media only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .mainBanner {
        background-image: url('../images/aboutBanner@2x.png');
    }
}

@media only screen and (max-width: 940px) {
    .aboutPageSection {
        text-align: center;
        padding-top: 30px;
    }

    .ourStorySubText {
        padding-right:0;
    }
}